export default {
	notes: [
		{ keycode: 'KeyA', char: 'A' },
		{ keycode: 'KeyW', char: 'W' },
		{ keycode: 'KeyS', char: 'S' },
		{ keycode: 'KeyE', char: 'E' },
		{ keycode: 'KeyD', char: 'D' },
		{ keycode: 'KeyF', char: 'F' },
		{ keycode: 'KeyT', char: 'T' },
		{ keycode: 'KeyG', char: 'G' },
		{ keycode: 'KeyY', char: 'Y' },
		{ keycode: 'KeyH', char: 'H' },
		{ keycode: 'KeyU', char: 'U' },
		{ keycode: 'KeyJ', char: 'J' },
		{ keycode: 'KeyK', char: 'K' },
		{ keycode: 'KeyO', char: 'O' },
		{ keycode: 'KeyL', char: 'L' },
		{ keycode: 'KeyP', char: 'P' },
		{ keycode: 'Semicolon', char: ';' },
		{ keycode: 'Quote', char: "'" },
	],
	voices: [
		{ keycode: 'Digit1', char: '1' },
		{ keycode: 'Digit2', char: '2' },
		{ keycode: 'Digit3', char: '3' },
		{ keycode: 'Digit4', char: '4' },
		// { keycode: 'Digit5', char: '5' },
		// { keycode: 'Digit6', char: '6' },
		// { keycode: 'Digit7', char: '7' },
		// { keycode: 'Digit8', char: '8' },
		// { keycode: 'Digit9', char: '9' },
		// { keycode: 'Digit0', char: '0' },
	],
	ambience: {
		down: { keycode: 'Minus', char: '-' },
		up: { keycode: 'Equal', char: '+' },
	},
	demo: { keycode: 'Backquote', char: '`' },
	pedals: {
		soften: { keycode: 'KeyV', char: 'V' },
		sostenuto: { keycode: 'KeyB', char: 'B' },
		sustain: { keycode: 'KeyN', char: 'N' },
	},
	metronome: { keycode: 'KeyM', char: 'M' },
	transpose: {
		down: { keycode: 'ArrowDown', char: '↓' },
		up: { keycode: 'ArrowUp', char: '↑' },
	},
	octave: {
		down: { keycode: 'ArrowLeft', char: '←' },
		up: { keycode: 'ArrowRight', char: '→' },
	},
}
